import { Container, Flex, Heading, SystemStyleInterpolation } from '@chakra-ui/react'
import React from 'react'

import ErrorBoundary from 'components/elements/ErrorBoundary'
import type { LayoutNotAuthorizedProps } from 'components/layouts/NotAuthorized'
import getLayoutNotAuthorized from 'components/layouts/NotAuthorized'
import Footer from 'components/modules/Footer'
import LoginHeader from 'components/modules/Login/LoginHeader'

export interface LayoutLoginProps extends LayoutNotAuthorizedProps {
  title?: string
}

const BG_IMAGE_URL = '/images/login-bg.jpg'

const TAG = 'Login'

export const loginStyles = {
  container: { display: 'flex', flexDirection: 'column', maxW: 'full', h: '100vh', p: 0 },
  wrapper: {
    flexDirection: 'column',
    maxW: 'full',
    h: 'full',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: { lg: `url(${BG_IMAGE_URL})` },
    boxShadow: { lg: 'inset 0 0 0 100vw rgba(0,0,0,0.6)' },
    backgroundSize: { lg: 'cover' },
    backgroundPosition: { lg: 'center' },
  },
  modal: {
    maxW: '428px',
    direction: 'column',
    bgColor: 'primary.white',
    padding: { base: 6, md: 10 },
    zIndex: 'modal',
    my: 6,
  },
  title: { size: 'lg', mb: 10 },
} as const satisfies SystemStyleInterpolation

const LayoutLogin: LayoutComponent<LayoutLoginProps> = ({ title, ...props }, page) =>
  getLayoutNotAuthorized(props)(
    <Container data-testid={TAG} {...loginStyles.container}>
      <LoginHeader />
      <Flex {...loginStyles.wrapper}>
        <Flex {...loginStyles.modal}>
          {title && (
            <Heading as="h1" {...loginStyles.title}>
              {title}
            </Heading>
          )}
          <ErrorBoundary>{page}</ErrorBoundary>
        </Flex>
      </Flex>
      <Footer />
    </Container>,
  )

function getLayoutLogin(props: LayoutLoginProps = {}): GetLayout {
  return (page) => LayoutLogin(props, page)
}

export default getLayoutLogin
