
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import getT from 'next-translate/getT'

import getLayoutLogin from 'components/layouts/Login'
import LoginMain from 'components/modules/Login/LoginMain'
import getRevalidate from 'config/revalidate'
import withPageStaticProps from 'utils/next/withPageStaticProps'

const TAG = 'LoginPage'

const LoginPage: NextPageWithLayout = LoginMain

LoginPage.displayName = TAG

LoginPage.getLayoutWithProps = getLayoutLogin

export default LoginPage

 const _getStaticProps = withPageStaticProps(async ({ locale }) => {
  if (locale === 'default' || !locale) {
    return { notFound: true }
  }

  const t = await getT(locale, 'auth')
  const title = t('pages.login.head.title')
  const description = t('pages.login.head.description')

  return {
    props: {
      layout: {
        props: {
          title: t('pages.login.title'),
          seo: { title, description },
        },
      },
    },
    revalidate: getRevalidate(),
  }
})


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login/index.page',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  