import { useMsal } from '@azure/msal-react'
import { Box, Button, Divider } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import Link from 'components/elements/Link'
import LoginTermsAndConditions from 'components/modules/Login/LoginTermsAndConditions'
import { loginRequest, resetPasswordRequest } from 'config/auth'

const TAG = 'LoginMain'

const styles = {
  wrapper: { textAlign: 'end' },
  button: { w: 'full', size: 'lg', mb: 6 },
  divider: { mt: 4, mb: 6, borderBottomWidth: 1 },
} as const

interface LoginMainProps {
  displayNewUserButton?: boolean
}

const LoginMain: FunctionComponent<LoginMainProps> = ({ displayNewUserButton = true }) => {
  const { t } = useTranslation('auth')

  const { instance } = useMsal()

  const signIn = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e)
    })
  }

  const forgotPassword = () => {
    instance.loginRedirect(resetPasswordRequest).catch((e) => {
      console.error(e)
    })
  }

  return (
    <>
      <LoginTermsAndConditions />
      <Button onClick={signIn} mt={4} mb={6}>
        {t('pages.login.form.submit')}
      </Button>
      <Box {...styles.wrapper}>
        <Button
          onClick={forgotPassword}
          aria-label={t('pages.login.forgotPassword.label')}
          variant="ternary"
          color="primary.red"
          fontWeight="normal"
        >
          {t('pages.login.forgotPassword.button')}
        </Button>
      </Box>
      {displayNewUserButton && (
        <>
          <Divider {...styles.divider} />
          <Button
            as={Link}
            href={process.env.NORMET_NO_ACCESS_TO_PORTAL_URL}
            variant="outline"
            aria-label={t('pages.login.contact.label')}
            rel="noopener noreferrer"
            {...styles.button}
          >
            {t('pages.login.contact.label')}
          </Button>
        </>
      )}
    </>
  )
}

LoginMain.displayName = TAG

export default LoginMain
